<template>
  <div class="col-3 col-md-3">
    <h3 class="row_title">{{caption}}</h3>
    <div class="row">
      <div class="footer">
        <ul class="footer_list" >
          <li>
            <div class="col-6 col-sm-12" v-for="(subType, inx_subType) in subTypeList" :key="'T'+inx_subType">
              <a href="#" @click="expandSubType(inx_subType)"><li :class="'static-subtype-item'+shouldCollapsed(inx_subType)">{{subTypeName(subType)}}</li></a>
              <div v-if="collapsed[inx_subType]"></div>
              <div v-else>
                <div class="col-6 col-sm-12" v-for="(item, inx) in getItemListWithSubType(subType[0])" :key="'I'+inx">
                  <a class="footer-item" :href="`/${item[0]}/?afterBook=true`" target="_blank" v-if="isNoBannerPage(subType[0])"> {{item[1]}}</a>
                  <a class="footer-item" :href="`/${item[0]}`" v-else> {{item[1]}}</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'footer-tree-column',
  props: {
    subTypeList: {
      type: Array,
      default: () => [],
    },
    itemList: {
      type: Array,
      default: () => [],
    },
    caption: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      newWindowPageList: 'GET_OPEN_NEW_WINDOW_FOOTER_PAGE',
    }),
    getItemListWithSubType() {
      return (subtype) => this.itemList.filter((item) => (item[2] === subtype));
    },
  },
  mounted() {
    this.subTypeList.forEach(() => {
      this.collapsed.push(true);
    });
  },
  data() {
    return {
      collapsed: [],
    };
  },
  methods: {
    // getItemListWithSubType(subtype) {
    //   return this.itemList.filter((item) => (item[2] === subtype));
    // },
    subTypeName(item) {
      if (this.lang === 'en') {
        return item[1];
      } else if (this.lang === 'he') {
        return item[2];
      } else if (this.lang === 'ru') {
        return item[3] || item[1];
      } else {
        return item[4] || item[1];
      }
    },
    expandSubType(inx) {
      const list = [];
      this.collapsed[inx] = !this.collapsed[inx];
      this.collapsed.forEach((item) => {
        list.push(item);
      });

      this.collapsed = list;
    },
    shouldCollapsed(inx) {
      if (inx === 0) return '';
      return this.collapsed[inx - 1] ? '' : ' expanded';
    },
    isNoBannerPage(code) {
      return this.newWindowPageList.includes(code);
    },
  },
};
</script>
<style scoped>
  li.static-subtype-item,
  a.footer-item{
    color: #e9e9e9 !important;
    text-decoration: none !important;
    font-weight: 300;
    transition: 0.5s;
  }
  li.static-subtype-item{
    margin-bottom: 10px;
    font-weight: 500;
  }
  li.static-subtype-item.expanded{
    margin-top: 15px;
  }
  a {
    color: #007bff;
    text-decoration: none !important;
    background-color: transparent;
  }
</style>
